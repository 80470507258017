import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { navigate } from "gatsby"

import device from "./device"

const FieldWrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;
  ${device.small`width: 100%;`}
  & > * {
    display: block;
  }
  label {
    color: var(--white);
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .input {
    width: 100%;
    margin: 0.5rem 0 1rem 0;
    padding: 0.5rem;
    border: 1px solid rgb(119, 119, 119, 0.3);
    background: var(--white);
    color: var(--black);
  }
  .error {
    position: absolute;
    bottom: 0.5rem;
    right: 5px;
    color: crimson;
    font-size: 0.7rem;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  button {
    background: var(--darkgrey);
    color: var(--white);
    border: 1px solid var(--white);
    font-size: 0.7rem;
    border: 0;
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    padding: calc(0.5rem - 1px) 1.5rem;
  }
`

const SubmitError = styled.div`
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  bottom: 1rem;
  left: 0;
  width: 100%;
  color: crimson;
`
const FieldWrapperRadio = styled(FieldWrapper)`
  label,
  input {
    display: inline-block;
  }
  input,
  .radioLabel {
    margin-left: 5px;
  }
`
const Disclaimer = styled.p`
  color: var(--white);
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const Schema = Yup.object().shape({
  corporate_or_leisure: Yup.string()
    .oneOf(["corporate", "leisure"], "Please select Corporate or Leisure.")
    .required("Please select Corporate or Leisure."),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  email_address: Yup.string()
    .email("Invalid email")
    .required("Required"),
  phone_number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  service_type: Yup.string()
    .oneOf(
      [
        "airportTransportation",
        "trainStation",
        "piersAndCruises",
        "hourlyAsDirected",
        "pointToPoint",
        "nightOnTheTown",
        "wedding",
        "prom",
        "sportingEvent",
        "specialOccasion",
        "other",
      ],
      "Please make a selection."
    )
    .required("Please make a selection."),
})

const PriceQuoteForm = () => {
  const [error, setError] = useState(false)
  function handleSubmit(values, setSubmitting, resetForm) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "pricequote",
        ...values,
      }),
    })
      .then(() => {
        navigate("/form-submitted/", {
          state: { submitted: true },
        })
      })
      .catch(() => {
        resetForm()
        setSubmitting(false)
        setError(true)
      })
  }

  return (
    <Formik
      initialValues={{
        corporate_or_leisure: "",
        first_name: "",
        last_name: "",
        email_address: "",
        questions_and_comments: "",
        service_type: "",
      }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm)
      }}
    >
      {({ isSubmitting }) => (
        <Form
          method="post"
          name="pricequote"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="pricequote" />

          <FieldWrapper>
            <label htmlFor="corporate_or_leisure">
              Corporate or Leisure? *
            </label>
            <Field
              as="select"
              name="corporate_or_leisure"
              className="input"
              required
            >
              <option value=""></option>
              <option value="corporate">Corporate</option>
              <option value="leisure">Leisure</option>
            </Field>
            <div className="error">
              <ErrorMessage name="corporate_or_leisure" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="first_name">First Name *</label>
            <Field type="text" name="first_name" className="input" required />
            <div className="error">
              <ErrorMessage name="first_name" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="last_name">Last Name *</label>
            <Field type="text" name="last_name" className="input" required />
            <div className="error">
              <ErrorMessage name="last_name" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="email">Email *</label>
            <Field
              type="email"
              name="email_address"
              className="input"
              required
            />
            <div className="error">
              <ErrorMessage name="email_address" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="phone_number">Phone Number</label>
            <Field type="tel" name="phone_number" className="input" />
            <div className="error">
              <ErrorMessage name="phone_number" />
            </div>
          </FieldWrapper>

          <FieldWrapperRadio>
            <label htmlFor="travel_type">Travel Type</label>
            <Field
              type="radio"
              name="travel_type"
              value="travel type hourly"
              id="hourly"
            />
            <label htmlFor="hourly" className="radioLabel">
              Hourly
            </label>

            <Field
              type="radio"
              name="travel_type"
              value="travel type point to point"
              id="point_to_point"
            />
            <label htmlFor="point_to_point" className="radioLabel">
              Point to Point
            </label>
          </FieldWrapperRadio>

          <FieldWrapper>
            <label htmlFor="pickup_date">Pickup Date</label>
            <Field type="date" name="pickup_date" className="input" />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="pickup_time">Pickup Time</label>
            <Field type="text" name="pickup_time" className="input" />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="pickup_city_state_zip">Pickup City/State/Zip</label>
            <Field type="text" name="pickup_city_state_zip" className="input" />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="dropoff_city_state_zip">
              Dropoff City/State/Zip
            </label>
            <Field
              type="text"
              name="dropoff_city_state_zip"
              className="input"
            />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="vehicle_type">Vehicle Type</label>
            <Field as="select" name="vehicle_type" className="input">
              <option value=""></option>
              <option value="sedan 1 to 3 pax">Sedan (1-3 PAX)</option>
              <option value="suv 1 to 6 pax">SUV (1-6 PAX)</option>
              <option value="limousine 1 to 6 pax">Limousine (1-6 PAX)</option>
              <option value="limousine 1 to 8 pax">Limousine (1-8 PAX)</option>
              <option value="van 1 to 10 pax">Van (1-10 PAX)</option>
              <option value="sprinter 1 to 14 pax">Sprinter (1-14 PAX)</option>
              <option value="luxury sprinter 1 to 10 pax">
                Luxury Sprinter (1-10 PAX)
              </option>
              <option value="wheelchair accessible van 1 to 12 pax">
                Wheelchair Accessible Van (1-12 PAX)
              </option>
              <option value="mini bus 1 to 21 pax">Mini-Bus (1-21 PAX)</option>
              <option value="mini bus 1 to 29 pax">Mini-Bus (1-29 PAX)</option>
              <option value="mini bus 1 to 37 pax">Mini-Bus (1-37 PAX)</option>
              <option value="luxury bus 1 to 20 pax">
                Luxury Bus (1-20 PAX)
              </option>
              <option value="luxury bus 1 to 30 pax">
                Luxury Bus (1-30 PAX)
              </option>
            </Field>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="requested_number_of_hours">
              Requested # of hours
            </label>
            <Field
              type="number"
              name="requested_number_of_hours"
              min="1"
              className="input"
            />
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="service_type">Service Type *</label>
            <Field as="select" name="service_type" className="input" required>
              <option value=""></option>
              <option value="airportTransportation">
                Airport Transportation
              </option>
              <option value="trainStation">Train Station</option>
              <option value="piersAndCruises">Piers and Cruises</option>
              <option value="hourlyAsDirected">Hourly (As Directed)</option>
              <option value="pointToPoint">Point to Point</option>
              <option value="nightOnTheTown">Night on the Town</option>
              <option value="wedding">Wedding</option>
              <option value="prom">Prom</option>
              <option value="sportingEvent">Sporting Event</option>
              <option value="specialOccasion">Special Occasion</option>
              <option value="other">Other</option>
            </Field>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="how_did_you_hear_about_us">
              How did you hear about us?
            </label>
            <Field
              as="select"
              name="how_did_you_hear_about_us"
              className="input"
            >
              <option value=""></option>
              <option value="internet search">Internet Search</option>
              <option value="hotel referral">Hotel Referral</option>
              <option value="customer referral">Customer Referral</option>
              <option value="friend neighbor referral">
                Friend/Neighbor Referral
              </option>
              <option value="phone book">Phone Book</option>
              <option value="facebook">Facebook</option>
              <option value="angies list">Angie's List</option>
            </Field>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="questions_and_comments">
              Questions &amp; Comments
            </label>
            <Field
              name="questions_and_comments"
              component="textarea"
              rows="5"
              className="input"
            />
          </FieldWrapper>
          <Disclaimer>
            By submitting this form, you consent to being contacted by us for
            business and marketing purposes.
          </Disclaimer>
          <ButtonWrapper>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </ButtonWrapper>
          {error && (
            <SubmitError>Something went wrong. Please try again!</SubmitError>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default PriceQuoteForm
